<template>
	<div>
	
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		created() {},
		methods: {},
		watch: {
			today_in(val, old) {
				this.todayIn()
			},
			today_out(val, old) {
				this.todayOut()
			},
			platform_gold(val, old) {
				this.platformGold()
			},
			success_ratio(val, old) {
				this.success()
			}
		},
	}
</script>

<style></style>
