import axios from 'axios'
import local from './local'
import {
	Message
} from 'element-ui'
const urlArr = [];
// const baseURL = 'http://aliapi.aoxiangae.com/' // 测试
// const baseURL = 'http://aliapi.qxalipay.com/' // 柒星
const baseURL = 'https://api.gtxjh.com/' // 钢铁聚合

const service = axios.create({
	baseURL: baseURL, //正式

	// withCredentials: true, // send cookies when cross-domain requests
	timeout: 30000 // request timeout;.
})

// request interceptor
service.interceptors.request.use(
	config => {
		config.headers = {
			'Content-Type': 'application/json' //  注意：设置很关键 
		}
		let data = {
			username: local.get('bpcusername'),
			tid: local.get('bpctid'),
			sub_telephone: local.get('telephone'),
			login_type: 2
		}
		// if(data.tid && data.username){			
		// 	config.data = Object.assign(config.data, data);
		// }
		// 	return config


		// do something before request is sent
		// console.log('==dd==', config)
		let fullUrl = config.baseURL + config.url
		if (config.allowRepetition) { //允许重复请求数据的接口
			if (config.url != '/carder/login/DoLogin' && config.url != '/carder/login/validateGcode' && config.url != '/carder/login/bindGoogle') {

				if (data.tid && data.username) {
					config.data = Object.assign(config.data, data);
				}else{
					window.location.href = '/'
					return Promise.reject({
						message: '账号未登录，请重新登录'
					})
				}
			}
			return config

		} else if (urlArr.indexOf(fullUrl) === -1) { //不允许重复请求数据的接口

			urlArr.push(fullUrl);
			setTimeout(() => {
				urlArr.splice(urlArr.indexOf(fullUrl), 1)
			}, 1000)

			if (config.url != '/carder/login/DoLogin'&& config.url != '/carder/login/validateGcode' && config.url != '/carder/login/bindGoogle') {
				if (data.tid && data.username) {
					config.data = Object.assign(config.data, data);
				}else{
					window.location.href = '/'
					return Promise.reject({
						message: '账号未登录，请重新登录'
					})
				}
			}
			return config
		} else {
			return Promise.reject({
				message: '重复请求'+config.url
			})
			// return
		}
	},
	error => {
		// do something with request error
		// console.log(error) // for debug
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	/**
	 * If you want to get http information such as headers or status
	 * Please return  response => response
	 */

	/**
	 * Determine the request status by custom code
	 * Here is just an example
	 * You can also judge the status by HTTP Status Code
	 */
	response => {
		const res = response.data
		// return res
		// console.log(response)
		// 错误码配置
		
		let fullUrl = response.request.responseURL
		urlArr.splice(urlArr.indexOf(fullUrl), 1)
		
		if (res.status != 1&&res.status != 3) {
			// Message({
			// 	message: res.msg || 'Error',
			// 	type: 'error',
			// 	duration: 5 * 1000
			// })
			if (res.status === 2) {
				// store.dispatch('user/resetToken')	
				local.remove('bpctid')
				local.remove('bpcusername')
				window.location.href = '/'
				return
			}
			if (res.status === 0) {
				return res
			}
			return Promise.reject(new Error(res.msg || 'Error'))
		} else {
			return res
		}
	},
	error => {
		// console.log('err' + error) // for debug
		Message({
			message: error.message,
			type: 'error',
			duration: 5 * 1000
		})
		return Promise.reject(error)
	}
)

export default service
