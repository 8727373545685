import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/main.vue'
import Login from '../views/login/index.vue'
import Directory from '../views/home.vue' 

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

// replace
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {
  return VueRouterReplace.call(this, to).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Main',
		component: Main,
		meta:{},
		directory:false,
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			noNav:true
		},
		directory:false,
	},
	{
		path: '/googleVerification',
		name: 'GoogleVerification',
		component: ()=>import('@/views/login/googleVerification.vue'),	
		meta: {
			noNav:true
		},	
		directory:false,
	},
	{
		path: '/replace',
		name: 'Replace',
		component: ()=>import('@/views/replace.vue'),
		meta: {
			noNav:true
		},
		directory:false,
	},
	{
		path: '/index',
		name: 'Index',
		component: () => import('@/views/index.vue'),
		meta: {
			id:1,
			title: '数据总览',
			leven:0,
			parentId:0,
			islink:true
		},
		directory:false
	},
	{
		path: '/order',
		name: 'Order',
		component: Directory,
		meta: {
			id:2,
			title: '订单管理',
			leven:0,
			parentId:0,
			islink:false,
			subAccount:true
		},
		directory:true
	},
	{
		path: '/orderPay',
		name: 'OrderPay',
		component: () => import('@/views/order/orderPay.vue'),
		meta: {
			id:21,
			title: '代付订单',
			leven:1,
			parentId:2,
			appType:1,
			islink:true,
			subAccount:true
		},
		directory:true
	},
	{
		path: '/orderRecharge',
		name: 'OrderRecharge',
		component: () => import('@/views/order/orderRecharge.vue'),
		meta: {
			id:22,
			title: '充值订单',
			leven:1,
			parentId:2,
			islink:true,
			subAccount:false
		},
		directory:true
	},
	{
		path: '/runVolume',
		name: 'RunVolume',
		component: () => import('@/views/order/runVolumeAlipay.vue'),
		meta: {
			id:23,
			title: '跑量统计',
			leven:1,
			parentId:2,
			appType:1,
			islink:true,
			subAccount:false
		},
		directory:true
	},
	{
		path: '/runVolume',
		name: 'RunVolume',
		component: () => import('@/views/order/runVolumeAlipay.vue'),
		meta: {
			id:231,
			title: '支付宝',
			leven:2,
			parentId:23,
			appType:1,
			islink:true,
			subAccount:false
		},
		directory:true
	},
	{
		path: '/runVolumeJuhema',
		name: 'RunVolumeJuhema',
		component: () => import('@/views/order/runVolumeJuhema.vue'),
		meta: {
			id:234,
			title: '聚合码',
			leven:2,
			parentId:23,
			appType:1,
			islink:true,
			subAccount:false
		},
		directory:true
	},
	{
		path: '/runVolumeNumber',
		name: 'RunVolumeNumber',
		component: () => import('@/views/order/runVolumeNumber.vue'),
		meta: {
			id:232,
			title: '数字人民币',
			leven:2,
			parentId:23,
			appType:1,
			islink:true,
			subAccount:false
		},
		directory:true
	},	
	{
		path: '/runVolumeNumberCard',
		name: 'RunVolumeNumberCard',
		component: () => import('@/views/order/runVolume.vue'),
		meta: {
			id:233,
			title: '银联',
			leven:2,
			parentId:23,
			appType:1,
			islink:true,
			subAccount:false
		},
		directory:true
	},		
	{
		path: '/arunVolumeCarder',
		name: 'aRunVolumeCarder',
		component: () => import('@/views/order/runVolumeCarder.vue'),
		meta: {
			id:24,
			title: '下级统计',
			leven:1,
			parentId:2,
			appType:1,
			islink:true,
			subAccount:false
		},
		directory:true
	},
	{
		path: '/passageWay',
		name: 'PassageWay',
		component: Directory,
		meta: {
			id:3,
			title: '通道管理',
			leven:0,
			parentId:0,
			islink:false,
			subAccount:false
		},
		directory:true
	},
	/*
	
	
	{
		path: '/passage',
		name: 'Passage',
		component: () => import('@/views/passage/passageCard.vue'),
		meta: {
			id:31,
			title: '通道管理',
			leven:1,
			parentId:3,
			islink:true,
			subAccount:false
		},
		directory:true
	},
	*/
	{
		path: '/passageZfbzzm',
		name: 'PassageZfbzzm',
		component: () => import('@/views/passage/passageZfbzzm.vue'),
		meta: {
			id:33,
			title: '支付宝转账码',
			leven:1,
			parentId:3,
			islink:true,
			subAccount:false,
			pay_type: 94
		},
		directory:true
	},
	{
		path: '/passageZfbklhb',
		name: 'PassageZfbklhb',
		component: () => import('@/views/passage/passageZfbklhb.vue'),
		meta: {
			id:37,
			title: '口令红包',
			leven:1,
			parentId:3,
			islink:true,
			subAccount:false,
			pay_type: 93
		},
		directory:true
	},
	{
		path: '/passageZfbuidxiao',
		name: 'PassageZfbuidxiao',
		component: () => import('@/views/passage/passageZfbuidxiao.vue'),
		meta: {
			id:38,
			title: '支付宝Uid小额',
			leven:1,
			parentId:3,
			islink:true,
			subAccount:false,
			pay_type: 95
		},
		directory:true
	},
	{
		path: '/passageZfbuidda',
		name: 'PassageZfbuidda',
		component: () => import('@/views/passage/passageZfbuidda.vue'),
		meta: {
			id:39,
			title: '支付宝Uid大额',
			leven:1,
			parentId:3,
			islink:true,
			subAccount:false,
			pay_type: 92
		},
		directory:true
	},
	{
		path: '/passageZfbzd',
		name: 'passageZfbzd',
		component: () => import('@/views/passage/passageZfbzd.vue'),
		meta: {
			id:35,
			title: '支付宝账单',
			leven:1,
			parentId:3,
			islink:true,
			subAccount:false,
			pay_type: 97
		},
		directory:true
	},
	{
		path: '/passageZfbaa',
		name: 'PassageZfbaa',
		component: () => import('@/views/passage/passageZfbaa.vue'),
		meta: {
			id:40,
			title: '支付宝AA',
			leven:1,
			parentId:3,
			islink:true,
			subAccount:false,
			pay_type: 91
		},
		directory:true
	},
	{
		path: '/passageZfbfzzz',
		name: 'PassageZfbfzzz',
		component: () => import('@/views/passage/passageZfbfzzz.vue'),
		meta: {
			id:36,
			title: '支付宝复制转账',
			leven:1,
			parentId:3,
			islink:true,
			subAccount:false,
			pay_type: 98
		},
		directory:true
	},
	{
		path: '/passageZfbsaoma',
		name: 'PassageZfbsaoma',
		component: () => import('@/views/passage/passageZfbsaoma.vue'),
		meta: {
			id:399,
			title: '支付宝扫码',
			leven:1,
			parentId:3,
			islink:true,
			subAccount:false,
			pay_type: 99
		},
		directory:true
	},
	{
		path: '/passageZfbshm',
		name: 'PassageZfbshm',
		component: () => import('@/views/passage/passageZfbshm.vue'),
		meta: {
			id:57,
			title: '支付宝售货码',
			leven:1,
			parentId:3,
			islink:true,
			subAccount:false,
			pay_type: 100
		},
		directory:true
	},
	{
		path: '/passageJuhema',
		name: 'PassageJuhema',
		component: () => import('@/views/passage/passageJuhema.vue'),
		meta: {
			id:34,
			title: '聚合码',
			leven:1,
			parentId:3,
			islink:true,
			subAccount:false
		},
		directory:true
	},
	{
		path: '/passageNumberMoney',
		name: 'PassageNumberMoney',
		component: () => import('@/views/passage/passageNumberMoney.vue'),
		meta: {
			id:32,
			title: '数字人民币',
			leven:1,
			parentId:3,
			islink:true,
			subAccount:false
		},
		directory:true
	},
	{
		path: '/passageCard',
		name: 'PassageCard',
		component: () => import('@/views/passage/passageCard.vue'),
		meta: {
			id:311,
			title: '银行卡转账',
			leven:2,
			parentId:31,
			fee_type:66,
			appType:1,
			isPayType:true,
			islink:true,
			subAccount:false
		},
		directory:true
	},
	{
		path: '/passageWeixin',
		name: 'PassageWeixin',
		component: () => import('@/views/passage/passageWeixin.vue'),
		meta: {
			id:312,
			title: '微信红包',
			leven:2,
			parentId:31,
			fee_type:76,
			appType:1,
			isPayType:true,
			islink:true,
			subAccount:false
		},
		directory:true
	},
	{
		path: '/passageAli',
		name: 'PassageAli',
		component: () => import('@/views/passage/passageAli.vue'),
		meta: {
			id:313,
			title: '支付宝收款',
			leven:2,
			parentId:31,
			fee_type:77,
			isPayType:true,
			islink:true,
			subAccount:false
		},
		directory:true
	},
	{
		path: '/passageAlisub',
		name: 'PassageAlisub',
		component: () => import('@/views/passage/shouKuanMaList.vue'),
		meta: {
			id:3131,
			title: '收款码',
			leven:2,
			parentId:31,
			isPayType:true,
			islink:true,
			subAccount:false
		},
		directory:true
	},
	{
		path: '/passageAlisub2',
		name: 'PassageAlisub2',
		component: () => import('@/views/passage/addShouKuanMa.vue'),
		meta: {
			id:3132,
			title: '新增收款码',
			leven:2,
			parentId:31,
			isPayType:true,
			islink:true,
			subAccount:false
		},
		directory:true
	},
	{
		path: '/report',
		name: 'Report',
		component: Directory,
		meta: {
			id:4,
			title: '报表管理',
			leven:0,
			parentId:0,
			islink:false,
			subAccount:false
		},
		directory:true
	},
	{
		path: '/reportWithdraw',
		name: 'ReportWithdraw',
		component: () => import('@/views/report/reportWithdraw.vue'),
		meta: {
			id:41,
			title: '提现记录',
			leven:1,
			parentId:4,
			islink:true,
			subAccount:false
		},
		directory:true
	},
	{
		path: '/reportLoss',
		name: 'ReportLoss',
		component: () => import('@/views/report/reportLoss.vue'),
		meta: {
			id:42,
			title: '报损记录',
			leven:1,
			parentId:4,
			appType:1,
			islink:true,
			subAccount:false
		},
		directory:true
	},
	{
		path: '/personal',
		name: 'Personal',
		component: Directory,
		meta: {
			id:5,
			title: '个人中心',
			leven:0,
			parentId:0,
			islink:false
		},
		directory:true
	},	
	{
		path: '/withdraw',
		name: 'Withdraw',
		component: () => import('@/views/personal/withdraw.vue'),
		meta: {
			id:51,
			title: '提现',
			leven:1,
			parentId:5,
			islink:true,
			subAccount:false
		},
		directory:true
	},	
	{
		path: '/cardWithdraw',
		name: 'WithdrawCard',
		component: () => import('@/views/personal/withdrawcard.vue'),
		meta: {
			id:52,
			title: '提现卡',
			leven:1,
			parentId:5,
			islink:true,
			subAccount:false
		},
		directory:true
	},
	{
		path: '/fans',
		name: 'Fans',
		component: () => import('@/views/personal/fans.vue'),
		meta: {
			id:53,
			title: '我的粉丝',
			leven:1,
			parentId:5,
			islink:true,
			subAccount:false
		},
		directory:true
	},
	{
		path: '/personalInfo',
		name: 'PersonalInfo',
		component: () => import('@/views/personal/personalInfo.vue'),
		meta: {
			id:54,
			title: '个人信息',
			leven:1,
			parentId:5,
			islink:true,
			subAccount:false
		},
		directory:true
	},
	{
		path: '/setTrueQuota',
		name: 'SetTrueQuota',
		component: () => import('@/views/personal/setTrueQuota.vue'),
		meta: {
			id:55,
			title: '修正卡余额',
			leven:1,
			parentId:5,
			appType:1,
			islink:true,
			subAccount:true
		},
		directory:true
	},
	{
		path: '/updatePWD',
		name: 'UpdatePWD',
		component: () => import('@/views/personal/updatePWD.vue'),
		meta: {
			id:56,
			title: '修改密码',
			leven:1,
			parentId:5,
			islink:true,
			subAccount:false
		},
		directory:true
	},
]

const router = new VueRouter({
	linkActiveClass: "activeClass",
	/*添加默认样式 activeClass   activeClass 写在组件样式里面   */
	routes
})

export default router
